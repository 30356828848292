import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query {
    logo: file(relativePath: { eq: "beacon-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  
  return (
    <Layout>
      <SEO title="Program Overview" />
      <div className="container primary-bg">
        <div className="row">
          <div className="col">
            <h1 className="small-padding">
              What is the CARES Act?
            </h1>
            <p className="small-padding">
              Coronavirus Aid, Relief, and Economic Security Act (CARES Act) was signed into law on March 27, 2020 by President Trump. The law provides provides financial and regulatory relief for those impacted by the the the novel coronavirus 2019 (“COVID-19”) pandemic. Through the Act, an unprecedented amount of $2.1 trillion was authorized to help various sectors of the economy recover.
            </p>
            
            <hr />
            <h1 className="small-padding">
              What is the Paycheck Protection Program?
            </h1>
            <p className="small-padding">
              The Paycheck Protection Program (PPP) provides small businesses with funds to pay up to 8 weeks of payroll costs including benefits. Funds can also be used to pay interest on mortgages, rent, and utilities.
            </p>
            <ul>
              <li>
                <strong>Fully Forgiven:</strong> Loans will be fully forgiven when used for payroll costs, interest on mortgages, rent, and utilities (at least 75% of the forgiven amount must have been used for payroll). Loan payments will be deferred for six months. No collateral or personal guarantees are required. Neither the government nor lenders will charge small businesses any fees. 
              </li>
              <li>
                <strong>Must Keep Employees on the Payroll—or Rehire Quickly:</strong> Forgiveness is based on the employer maintaining or quickly rehiring employees and maintaining salary levels. Forgiveness will be reduced if full-time headcount declines, or if salaries and wages decrease. 
              </li>
              <li>
                <strong>Eligible Small Businesses:</strong> Small businesses with 500 or fewer employees—including nonprofits, veterans organizations, tribal concerns, self-employed individuals, sole proprietorships, and independent contractors— are eligible. Businesses with more than 500 employees are eligible in certain industries.
              </li>
              <li>
                <strong>When to Apply:</strong> Starting April 3, 2020, small businesses and sole proprietorships can apply. Starting April 10, 2020, independent contractors and self-employed individuals can apply. We encourage you to apply as quickly as you can because there is a funding cap.  
              </li>
              <li>
                <strong>How to Apply:</strong> You can apply through any existing SBA 7(a) lender or through any federally insured depository institution, federally insured credit union, and Farm Credit System institution that is participating. Other regulated lenders will be available to make these loans once they are approved and enrolled in the program. You should consult with your local lender as to whether it is participating, as it is recommended that applicants use their existing lenders if they are approved. All loans will have the same terms regardless of lender or borrower. 
              </li>
            </ul>
            <hr />
            <div className="center-container">
              <Link className="button btn-primary primary-cta ad-hoc-btn" to="/funding">
                Register with BizHelp.Miami
              </Link>
            </div>
            
            <a className="full-cta" href="https://home.treasury.gov/system/files/136/PPP--Fact-Sheet.pdf" target="_blank">
              Review US Treasury PPP information for Borrowers 
            </a>
            <a className="full-cta" href="https://home.treasury.gov/system/files/136/Paycheck-Protection-Program-Application-3-30-2020-v3.pdf" target="_blank">
              Download official PPP loan application for Small Businesses
            </a>
            <a className="full-cta" href="https://www.beaconcouncil.com/wp-content/uploads/2020/04/FAQ_MDBC_PAYCHECK-PROTECTION-PROGRAM_04.08.2020.pdf" target="_blank">
              Download Paycheck Protection Program FAQ
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
